<template>
  <section>
    <b-row class="match-height">
      <b-col md="8">
        <b-card>
          <b-card-text>
            <h2 class="mb-0"><small class="color-primary">Ciao</small><br />{{ this.userData.name }}</h2>
            <p>Se un <strong>{{ this.userData.role }}</strong></p>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col md="4">
        <b-card
          title="Altre informazioni"
          class="mb-2"
        >
          <b-card-text>
            ...
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        xl="6"
        md="12"
      >
        <b-card
          title="Attività in corso"
          class="mb-2"
        >
          <b-card-text>
            ...
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        xl="6"
        md="12"
      >
        <b-card
          title="Area Statistiche"
          class="mb-2"
        >
          <b-card-text>
            ...
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BImg, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard, 
    BCardText,
    BAvatar,
    BImg,

  },
  data() {
    return {
      userData: {},
    }
  },
  created() {
    // dati reali
    this.userData = JSON.parse(localStorage.getItem('userData'))
    //console.log(this.userData)
  },
  methods: {

  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
